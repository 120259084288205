import * as actions from './actions';
import { all, call, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { authenticationApi } from '../../api';
import storage from '../../utils/storage';
import { getErrorCode, getErrorMessage, removeAccessToken, serverIssueStatusCodes, setAccessToken } from '../../api/network';
import { getType } from 'typesafe-actions';
import { getAuth } from './selectors';
import * as semesterActions from '../semester/actions';
import { getCurrentTimer } from '../timer/operations';
import {
  EXPIRED_SUBSCRIPTION,
  LINK_EXPIRED,
  LOGIN,
  MOBILE_SIGNUP_INSTRUCTIONS,
  VERIFY_EMAIL
} from '../../utils/constants/routes';
import { LOGIN_SHORT_PASSWORD_ERROR, RESET_PASSWORD_CODE_EXPIRED_ERROR } from '../../utils/constants/auth';
import {
  clearState,
  onAppStart,
  setCoachSessionFlag,
  subscriptionExpired,
  subscriptionRenewed,
  verificationRequired
} from '../common/actions';
import { getSemesterRangeInfo } from '../semester/selectors';
import { getBadgesInfo, getUserSettings, getUserSubscription } from '../settings/actions';
import { startNotificationsListener } from '../notifications/actions';
import { SemesterStatus } from 'shovel-lib/types';
import { timeUtils } from 'shovel-lib';
import { goToHomeLink } from '../../components/navigation/navigationHelpers';
import { getSubscriptionExpired } from '../common/selectors';
import jwtDecode from 'jwt-decode';
import sentryConfig from '../../config/sentry';
import { addFullStory } from '../../config/fullStory';
import { alert, NotificationMode } from '@components/common/snackbarNotifications/Notifications';
import t from '../../i18n/t';
import { commitmentsData } from '../calendar/actions';
import gaAnalytics, { AnalyticsEvent } from '../../config/gaAnalytics';
import { triggerIcsUpdates } from '../ics/actions';
import { isMobileApp } from '../../utils/screenUtils';
import { ApiErrorResponse } from 'apisauce';

// todo ignore logic, needs refactor
export function* login(action: ReturnType<typeof actions.login.request>) {
  const { ok, ...response } = yield call(authenticationApi.login, action.payload);
  if (ok) {
    yield call(setup, response.data);
  } else {
    if (getErrorCode(response) === LOGIN_SHORT_PASSWORD_ERROR) {
      alert(t.INVALID_CREDENTIALS);
    } else {
      yield call(handleError, actions.login.failure, response);
    }
  }
}

export function* refreshAccessToken() {
  const { refreshingToken } = yield select(getAuth);
  // refresh token action is already in progress..
  if (refreshingToken) {
    // await for either success or failure from a previous operation
    const action = yield take([getType(actions.refreshToken.success), getType(actions.refreshToken.failure)]);
    return {
      ok: action.type === getType(actions.refreshToken.success)
    };
  }

  yield call(removeAccessToken);

  yield put(actions.refreshToken.request());

  const refreshToken = yield call(storage.getRefreshToken);
  const { ok, ...response } = yield call(authenticationApi.refreshToken, refreshToken);
  if (ok) {
    const { accessToken, refreshToken, isOrganization, emailVerified, email } = response.data;
    yield call(setTokens, accessToken, refreshToken);
    const jwtPayload = jwtDecode(accessToken);
    if (jwtPayload.auth === 'STUDENT_EXPIRED') {
      const alreadySetSubscriptionExpired = (yield select(getSubscriptionExpired)).subscriptionExpired;
      if (!alreadySetSubscriptionExpired) {
        yield put(subscriptionExpired());
        yield put(push(EXPIRED_SUBSCRIPTION));
      }
    }
    if (jwtPayload['coach_id']) {
      yield put(setCoachSessionFlag());
    }
    if (isOrganization && !emailVerified) {
      yield put(push({ pathname: VERIFY_EMAIL, state: { email } }));
      yield put(verificationRequired());
    }
    yield put(actions.refreshToken.success());
  } else {
    yield put(actions.refreshToken.failure(getErrorMessage(response)));
    // don't log out user if the error is related to the server issue
    if (!serverIssueStatusCodes.includes(response.status ?? null)) {
      yield put(actions.logout());
    }
  }
  return { ok };
}

export function* setTokens(accessToken: string, refreshToken: string) {
  return yield all([call(setAccessToken, accessToken), call(storage.setRefreshToken, refreshToken)]);
}

export function* removeStorageData() {
  yield all([
    call(removeAccessToken),
    call(storage.removeRefreshToken),
    call(storage.removeUserId),
    call(storage.removeFcmToken)
  ]);
}

export function* forgotPassword(action: ReturnType<typeof actions.forgotPassword.request>) {
  const { email } = action.payload;
  const { ok, ...response } = yield call(authenticationApi.forgotPassword, { email });
  if (ok) {
    yield put(actions.forgotPassword.success(email));
  } else {
    yield call(handleError, actions.forgotPassword.failure, response);
  }
}

export function* resetPassword(action: ReturnType<typeof actions.resetPassword.request>) {
  const { newPassword, resetPasswordCode } = action.payload;
  const { ok, ...response } = yield call(authenticationApi.resetPassword, { newPassword, resetPasswordCode });
  if (ok) {
    yield put(actions.resetPassword.success());
  } else {
    if (getErrorCode(response) === RESET_PASSWORD_CODE_EXPIRED_ERROR) {
      yield put(actions.resetPassword.failure(getErrorMessage(response)));
      yield put(push(LINK_EXPIRED));
    } else {
      yield call(handleError, actions.resetPassword.failure, response);
    }
  }
}

export function* setPassword(action: ReturnType<typeof actions.setPassword.request>) {
  const { newPassword, code } = action.payload;
  const { ok, ...response } = yield call(authenticationApi.setPassword, { newPassword, code });
  if (ok) {
    yield put(actions.setPassword.success());
    yield call(setup, response.data);
    yield call(gaAnalytics.track, AnalyticsEvent.RedeemAccess.name);
  } else {
    yield call(handleError, actions.setPassword.failure, response);
  }
}

export function* logout(action: ReturnType<typeof actions.logout>) {
  // yield fork(helpCrunch.logout);
  yield call(removeStorageData);
  yield put(clearState());
  const { redirectUrl } = (action.payload as { redirectUrl?: string }) ?? {};
  if (redirectUrl) {
    window.location.href = redirectUrl;
  } else {
    yield put(onAppStart({ location: { pathname: LOGIN } }));
  }
}

export function* signUp(action: ReturnType<typeof actions.signUp.request>) {
  const { ok, ...response } = yield call(authenticationApi.signUp, action.payload);
  if (ok) {
    yield call(setup, response.data);
    yield call(gaAnalytics.track, AnalyticsEvent.Registration.name);
  } else {
    yield call(handleError, actions.signUp.failure, response);
  }
}

export function* signUpMobile(action: ReturnType<typeof actions.signUpMobile.request>) {
  const { ok, ...response } = yield call(authenticationApi.signUpMobile, action.payload);
  if (ok) {
    yield put(push({ pathname: MOBILE_SIGNUP_INSTRUCTIONS, state: { email: action.payload.email } }));
    yield call(gaAnalytics.track, AnalyticsEvent.MobileRegistration.name);
  } else {
    yield call(handleError, actions.signUpMobile.failure, response);
  }
}

export function* signUpOrganization(action: ReturnType<typeof actions.signUpOrganization.request>) {
  const { organizationId, organizationEmailDomainName, ...request } = action.payload;
  const { ok, ...response } = yield call(authenticationApi.signUpOrganization, organizationId, request);
  if (ok) {
    yield call(setup, response.data);
    yield call(gaAnalytics.track, AnalyticsEvent.Registration.name, AnalyticsEvent.Registration.params(organizationId));
  } else {
    const errorMessage = getErrorMessage(response);
    if (errorMessage === 'organization.domain.name.exception') {
      yield alert(`Use your ${organizationEmailDomainName} email address`);
    } else {
      yield call(handleError, actions.signUpOrganization.failure, response);
    }
  }
}

export function* signUpOrganizationMobile(action: ReturnType<typeof actions.signUpOrganizationMobile.request>) {
  const { organizationId, organizationEmailDomainName, ...request } = action.payload;
  const { ok, ...response } = yield call(authenticationApi.signUpOrganizationMobile, organizationId, request);
  if (ok) {
    yield put(push({ pathname: MOBILE_SIGNUP_INSTRUCTIONS, state: { email: action.payload.email } }));
    yield call(
      gaAnalytics.track,
      AnalyticsEvent.MobileRegistration.name,
      AnalyticsEvent.MobileRegistration.params(organizationId)
    );
  } else {
    const errorMessage = getErrorMessage(response);
    if (errorMessage === 'organization.domain.name.exception') {
      yield alert(`Use your ${organizationEmailDomainName} email address`);
    } else {
      yield call(handleError, actions.signUpOrganizationMobile.failure, response);
    }
  }
}

export function* resetTrialFromDashboard() {
  const { ok, ...response } = yield call(authenticationApi.resetTrialFromDashboard);
  if (ok) {
    alert(t.RESET_TRIAL_SUCCESS, NotificationMode.SUCCESS);
    yield put(subscriptionRenewed());
    yield call(setup, response.data);
    yield call(gaAnalytics.track, AnalyticsEvent.ResetTrial.name);
  } else {
    yield call(handleError, actions.resetTrialFromDashboard.failure, response);
  }
}

export function* resendActivationEmail(action: ReturnType<typeof actions.resendActivationEmail.request>) {
  const { email } = action.payload;
  const { ok, ...response } = yield call(authenticationApi.resendActivationLink, { email });
  if (ok) {
    yield put(actions.resendActivationEmail.success(email));
    alert(t.RESEND_ACTIVATION_EMAIL_SUCCESS(email), NotificationMode.SUCCESS);
    yield put(push({ pathname: MOBILE_SIGNUP_INSTRUCTIONS, state: { email } }));
  } else {
    yield call(handleError, actions.resendActivationEmail.failure, response);
  }
}

export function* setup(data: any) {
  const {
    accessToken,
    refreshToken,
    userId,
    role,
    isOrganization,
    organizationId,
    emailVerified,
    email: userEmail,
    firstName
  } = data;
  // if a user tries to login into another account, we need to wipe out all the previous state
  const oldUserId = yield call(storage.getUserId);
  if (oldUserId !== userId) {
    yield call(removeStorageData);
    yield call(storage.setUserId, userId);
  }

  yield fork(gaAnalytics.identifyUser, userEmail, organizationId);

  yield call(setTokens, accessToken, refreshToken);

  // yield fork(helpCrunch.authenticate, userId, userEmail, firstName, isMobileApp);

  // todo remove
  // if (isMobileApp) {
  //   yield call(mobileBridgeApi.sendMessage, {
  //     type: OutputType.SIGNED_UP,
  //     payload: {
  //       userId,
  //       accessToken,
  //       refreshToken
  //     }
  //   });
  // }

  if (role === 'STUDENT_EXPIRED') {
    yield put(subscriptionExpired());
    yield put(getUserSettings.request());
    yield put(getUserSubscription.request());
    yield put(push(EXPIRED_SUBSCRIPTION));
    yield put(actions.login.success());
    return;
  }

  if (isOrganization && !emailVerified) {
    yield put(verificationRequired());
    yield put(push({ pathname: VERIFY_EMAIL, state: { email: userEmail } }));
    yield put(actions.login.success());
    return;
  }

  yield put(semesterActions.getCurrent.request());
  const currentSemesterAction = yield take(getType(semesterActions.getCurrent.success));

  const semester = currentSemesterAction.payload;
  const { email } = semester.user;

  // todo figure out how to set context before applying logic for STUDENT_EXPIRED (this code is unreachable in that case)
  yield fork(sentryConfig.setUserContext, { email });
  yield fork(addFullStory, userId, email);

  // TODO : call signUp.success in case of signup
  yield put(actions.login.success());

  yield put(commitmentsData.request());
  yield put(getUserSettings.request());
  yield put(getBadgesInfo.request());
  yield put(getUserSubscription.request());

  yield put(startNotificationsListener());

  const semesterDetails = yield select(getSemesterRangeInfo);
  const location =
    [
      SemesterStatus.COURSE_CREATED,
      SemesterStatus.QUICK_SETUP_DONE,
      SemesterStatus.ACTIVITY_SETUP_DONE,
      SemesterStatus.TASK_CREATED
    ].includes(semester.state) && timeUtils.now().isBefore(semesterDetails.ends)
      ? goToHomeLink(semester.id)
      : `/${semester.id}/term`;

  // if (isMobile) {
  // if (location === goToHomeLink(semester.id)) {
  //   yield put(push(MOBILE_LOGIN));
  // } else {
  //   yield put(push(CONGRATS_MOBILE));
  // }
  // } else {
  yield put(push(location));
  if (
    ![SemesterStatus.CREATED, SemesterStatus.SEMESTER_SETUP_DONE, SemesterStatus.AWAKE_TIMES_SETUP_DONE].includes(
      semester.state
    )
  ) {
    yield put(triggerIcsUpdates.request());
  }
  // this one needs to go last. It will not finish until we stop the timer
  yield call(getCurrentTimer);
  // }
}

export function* handleError(action: any, response: ApiErrorResponse<any>) {
  const errorMessage = getErrorMessage(response);
  yield put(action(errorMessage));
  if (errorMessage && errorMessage.startsWith('User with email')) {
    yield alert(errorMessage);
  } else {
    yield alert(t[errorMessage]);
  }
}

export default function*() {
  yield all([
    takeLatest(getType(actions.login.request), login),
    takeLatest(getType(actions.forgotPassword.request), forgotPassword),
    takeLatest(getType(actions.resetPassword.request), resetPassword),
    takeLatest(getType(actions.setPassword.request), setPassword),
    takeLatest(getType(actions.logout), logout),
    takeLatest(getType(actions.signUp.request), signUp),
    takeLatest(getType(actions.signUpMobile.request), signUpMobile),
    takeLatest(getType(actions.signUpOrganization.request), signUpOrganization),
    takeLatest(getType(actions.signUpOrganizationMobile.request), signUpOrganizationMobile),
    takeLatest(getType(actions.resetTrialFromDashboard.request), resetTrialFromDashboard),
    takeLatest(getType(actions.resendActivationEmail.request), resendActivationEmail)
  ]);
}
