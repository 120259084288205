import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';
import { FlexRow, Row } from '../layoutUtils';
import { ExtraLargeText, RegularText } from '@utils/typography';
import ProgressBar from '../progressBar/ProgressBar';
import colors from '@utils/colors';
import MaterialIcon from '../icons/MaterialIcon';
import { Badge } from './Badge';
import storage from '@utils/storage';
import Tooltip from '../tooltip/Tooltip';
import { badgeNames, allBadges } from './helpers';
import { allSteps, badgeTooltip } from './steps';
import { RootState } from '@state/types';
import { BadgesInfo } from '@state/settings/types';
import ModalDialog from '../ModalDialog';
import {
  cancelBadgesObviousMike,
  checkSubstep,
  closeStepDialog,
  openStepDialog,
  updateBadgesInfo
} from '@state/settings/actions';
import BadgeDialog from './BadgeDialog';
import { getSetupTermError, isSetupInProgress } from '@state/terms/selectors';
import t from '@i18n/t';
import StepDialog from './StepDialog';
import { settingsSelectors } from '@state/settings';
import { screenSizes } from '@utils/screenUtils';
import StepIcon from './steps/StepIcon';
import { PaymentProcessor } from 'shovel-lib/types';

type PropsFromDispatch = {
  checkSubstep: typeof checkSubstep;
  closeStepDialog: typeof closeStepDialog;
  openStepDialog: typeof openStepDialog;
  updateBadgesInfo: typeof updateBadgesInfo.request;
  cancelBadgesObviousMike: typeof cancelBadgesObviousMike;
};
type PropsFromState = {
  info: BadgesInfo | null;
  open: boolean;
  step?: string;
  setupTermLoading: boolean;
  setupTermError?: string;
  isTrial: boolean;
  paymentProcessor?: PaymentProcessor;
  badgesObviousMike: boolean;
  showOnboardingBar: boolean;
};
type Props = PropsFromDispatch & PropsFromState & { visible: boolean };

type State = {
  expanded: boolean;
  badgeDialogOpen: boolean;
  badgeDialogInfo?: {
    name?: string;
    initial?: boolean;
    overviewGifSequence?: boolean;
    final?: boolean;
  };
};

class OnboardingBar extends PureComponent<Props, State> {
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (!prevProps.info || !this.props.info) return;

    if (prevProps.info!.badges !== this.props.info!.badges) {
      if (this.props.info!.badges.length === allBadges.length && !this.props.info!.completed) {
        this.setState({ badgeDialogOpen: true, badgeDialogInfo: { final: true } });
        return;
      }

      const newBadge = this.props.info!.badges.find(badge => !prevProps.info!.badges.includes(badge));
      if (!newBadge) return;
      this.setState({
        badgeDialogOpen: true,
        badgeDialogInfo: {
          name: newBadge,
          initial: newBadge === t[badgeNames.ROCKSTAR_ROOKIE]
        }
      });
    }
  }

  readonly state: State = {
    expanded: storage.getOnboardingBarExpanded(),
    badgeDialogOpen: false,
    badgeDialogInfo: undefined
  };

  // openOverviewGifSequence = () => {
  //   setTimeout(() => this.setState({ badgeDialogOpen: true, badgeDialogInfo: { overviewGifSequence: true } }), 1000);
  // };

  collapseBar = () => {
    this.setState({ expanded: false });
    storage.setOnboardingBarExpanded(false);
  };

  expandBar = () => {
    this.setState({ expanded: true });
    storage.setOnboardingBarExpanded(true);
  };

  completeOnboarding = () => {
    const { info, updateBadgesInfo } = this.props;
    const { badges, steps, substeps } = info!;
    updateBadgesInfo({ badges, steps, substeps, completed: true });
  };

  renderStep = stepName => (
    <StepIcon
      key={stepName}
      stepName={stepName}
      completed={this.props.info!.steps.includes(stepName)}
      active={stepName === this.props.step}
      badgesObviousMike={this.props.badgesObviousMike}
      cancelBadgesObviousMike={this.props.cancelBadgesObviousMike}
      openStepDialog={this.props.openStepDialog}
    />
  );

  render() {
    const { expanded, badgeDialogOpen, badgeDialogInfo } = this.state;
    const { info, isTrial, visible, showOnboardingBar } = this.props;

    if (!info) {
      return null;
    }

    const { steps, badges } = info;

    if (!showOnboardingBar) {
      return this.renderDialogs();
    }

    const stepsCompleted = steps.length - 1;
    const { QUICK_SETUP_STEP, ...stepsWithoutQuickSetup } = allSteps;
    const stepNames = Object.keys(stepsWithoutQuickSetup);
    const stepsCompletedPercent = parseFloat(((stepsCompleted * 100) / stepNames.length).toFixed(2));
    return (
      <Bar
        expanded={expanded}
        onClick={!expanded ? this.expandBar : undefined}
        badgeDialogOpen={badgeDialogOpen}
        visible={visible}
      >
        <Content>
          <ProgressRow>
            <Text>
              {stepsCompleted}/{stepNames.length}
            </Text>
            <StepProgressBar
              percentage={stepsCompletedPercent.toString()}
              //@ts-ignore
              percentageIncludingSession={0}
              progressColor={colors.positive}
              trackColor={colors.lightPositive}
              simple
            />
            {/*<Text color={colors.darkGray}>{stepsCompletedPercent}%</Text>*/}
          </ProgressRow>
          <StepsRow>{stepNames.map(this.renderStep)}</StepsRow>
          <BadgesRow>
            {allBadges.map(badge => (
              <Badge
                {...badge}
                completed={badges.includes(badge.name)}
                tooltip={badgeTooltip(badge.name, badge.steps, isTrial)}
              />
            ))}
            <CloseIcon name={'close'} onClick={this.collapseBar} />
          </BadgesRow>
        </Content>
        <Tooltip
          target={
            <IconWrapper expanded={expanded}>
              <Icon>👋</Icon>
            </IconWrapper>
          }
          content={t.DISCOVER_SHOVEL}
          position={'top center'}
          width={'max-content'}
        />
        {this.renderDialogs()}
      </Bar>
    );
  }

  renderDialogs = () => {
    const { badgeDialogOpen, badgeDialogInfo } = this.state;
    const { checkSubstep, open, step: stepName, info, isTrial, paymentProcessor } = this.props;

    const step = allSteps[stepName!];
    const completed = step && info!.steps.includes(stepName!);
    const title = step ? (
      <span>
        {step.number}. {step.title}
        <span style={{ color: colors.positive }}> {completed && `(${t.COMPLETED})`}</span>
      </span>
    ) : (
      ''
    );
    const Component = step ? step.component || StepDialog : undefined;

    return (
      <>
        <BadgeDialog
          freeTrial={isTrial}
          paymentProcessor={paymentProcessor}
          {...badgeDialogInfo}
          open={badgeDialogOpen}
          onClose={() => {
            const callback = this.state.badgeDialogInfo?.final
              ? this.completeOnboarding
              : // : this.state.badgeDialogInfo?.initial
                // ? this.openOverviewGifSequence
                undefined;
            this.setState({ badgeDialogOpen: false, badgeDialogInfo: undefined }, callback);
          }}
        />
        <ModalDialog
          open={open}
          onClose={() => this.props.closeStepDialog()}
          title={title}
          fullWidth={true}
          closeOnDocumentClick={false}
          closeOnEscape={false}
          noErrorPlaceholder
        >
          {Component ? (
            <Component
              onClose={() => this.props.closeStepDialog()}
              completed={completed}
              checkSubstep={checkSubstep}
              step={step}
              stepName={stepName}
              userProgress={info}
            />
          ) : (
            <div />
          )}
        </ModalDialog>
      </>
    );
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      checkSubstep,
      closeStepDialog,
      openStepDialog,
      updateBadgesInfo: updateBadgesInfo.request,
      cancelBadgesObviousMike
    },
    dispatch
  );

const mapStateToProps = (state: RootState) => ({
  info: settingsSelectors.getUserBadges(state),
  ...settingsSelectors.getStepDialogInfo(state),
  setupTermLoading: isSetupInProgress(state),
  setupTermError: getSetupTermError(state),
  isTrial: settingsSelectors.getDaysTrialLeft(state) !== null,
  paymentProcessor: settingsSelectors.getPaymentProcessor(state),
  badgesObviousMike: settingsSelectors.getBadgesObviousMike(state),
  showOnboardingBar: settingsSelectors.getShowOnboardingBar(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingBar);

const Content = styled(FlexRow)`
  ${FlexRow}:not(:last-child) {
    margin-right: 20px;
  }
`;

const IconWrapper = styled(Row)<{ expanded: boolean }>`
  width: ${props => (props.expanded ? 0 : '100%')};
  height: 100%;
  justify-content: center;
  ${props => !props.expanded && 'transition: width 0s ease 0.4s;'}
`;

const Icon = styled(ExtraLargeText)``;

const Bar = styled(Row)<{ expanded: boolean; badgeDialogOpen: boolean; visible: boolean }>`
  justify-content: center;
  position: fixed;
  bottom: 60px;
  border-radius: 30px;
  height: 40px;
  right: 100px;
  width: ${props => (props.expanded ? '800px' : '40px')};
  ${props => !props.expanded && 'cursor: pointer'};
  z-index: ${props => (!props.visible ? -1 : props.badgeDialogOpen ? 1000 : 99)};
  background: ${props => props.theme.onboardingBarBackground};
  border: ${props => `1px solid ${props.theme.onboardingBarBackground}`};
  box-sizing: border-box;
  box-shadow: ${({ theme }) => `0px 4px 4px ${theme.boxShadow}, 0px 0px 2px ${theme.boxShadow};`};
  transition: width 0.6s ease;

  ${Content} {
    justify-content: space-between;
    padding: ${props => (props.expanded ? '10px 20px' : 0)};
    flex: ${props => (props.expanded ? 1 : 0)};

    ${props => !props.expanded && 'visibility: hidden;'};
    opacity: ${props => (props.expanded ? 1 : 0)};
    transition: ${props => (props.expanded ? 'opacity 0.6s ease-in' : 'opacity 0s ease-out')};
  }

  ${Icon} {
    width: ${props => (!props.expanded ? 'auto' : 0)};
    opacity: ${props => (props.expanded ? 0 : 1)};
    transition: opacity ${props => (props.expanded ? '0s' : '0.2s')} ease;
    transition-delay: ${props => (props.expanded ? '0s' : '0.6s')};
  }
`;

const StepProgressBar = styled(ProgressBar)`
  height: 0;
  margin: 0 10px;
  flex: 1;
`;

const CloseIcon = styled(MaterialIcon)`
  margin-left: 10px;
  color: ${props => props.theme.onboardingBarTextColor};
`;

const Text = styled(RegularText)`
  white-space: nowrap;
  color: ${props => props.theme.onboardingBarTextColor};
`;

const ProgressRow = styled(FlexRow)`
  max-width: 150px;
  @media (max-width: ${screenSizes.medium}) {
    display: none;
  }
`;

const StepsRow = styled(FlexRow)`
  justify-content: center;
  @media (max-width: ${screenSizes.medium}) {
    justify-content: flex-start;
  }
`;

const BadgesRow = styled(FlexRow)`
  justify-content: flex-end;
  @media (max-width: ${screenSizes.small}) {
    > div {
      width: 28px;
      height: 28px;
      margin-right: 2px;
      flex-shrink: 0;
      span {
        font-size: 18px;
      }
    }
  }
`;
