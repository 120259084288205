import { all, call, CallEffect, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { push } from 'connected-react-router';

import * as authActions from '../auth/actions';
import * as authOperations from '../auth/operations';
import { getErrorMessage } from '../../api/network';
import storage from '../../utils/storage';
import {
  authSections,
  BROWSER_NOT_SUPPORTED,
  LOGIN,
  MAINTENANCE,
  PARENT_PAYMENT,
  PAYMENT_SUCCESS,
  SIGN_UP,
  VERIFY_EMAIL
} from '../../utils/constants/routes';
import * as actions from './actions';
import { getId, getSemesterStatus } from '../semester/selectors';
import * as semesterActions from '../semester/actions';
import { getCurrentTimer } from '../timer/operations';
import { ApiErrorResponse } from 'apisauce';
import { alert, NotificationMode } from '@components/common/snackbarNotifications/Notifications';
import t from '../../i18n/t';
import { SemesterStatus } from 'shovel-lib/types';
import { commitmentsData } from '../calendar/actions';
import { isIE, isMobile, isMobileApp } from '../../utils/screenUtils';
import { getBadgesInfo, getUserSettings, getUserSubscription } from '../settings/actions';
import { startNotificationsListener } from '../notifications/actions';
import { goToHomeLink } from '../../components/navigation/navigationHelpers';
import { getSubscriptionExpired, getVerificationRequired } from './selectors';
import sentryConfig from '../../config/sentry';
import { authenticationApi } from 'shovel-lib';
import { addFullStory } from '../../config/fullStory';
import { setup } from '../auth/operations';
import * as commonActions from './actions';
import * as commonSelectors from './selectors';
import { courseActions } from '../course';
import gaAnalytics from '../../config/gaAnalytics';
import accessibe from '../../config/accessibe';
import { triggerIcsUpdates } from '../../state/ics/actions';

const isMaintenance = process.env.REACT_APP_IS_MAINTENANCE === 'true';

export function* callApi(apiCall: CallEffect) {
  const response = yield apiCall;
  if (response.status !== 401) {
    return response;
  }

  // try to refresh token, if it fails, it takes the user to login screen
  const { ok } = yield call(authOperations.refreshAccessToken);
  if (ok) {
    // re-try function with new authentication header
    return yield apiCall;
  }

  while (true) {
    const action = yield take([getType(authActions.login.success)]);
    if (action.type === getType(authActions.login.success)) {
      // when it succeeds, break the cycle
      break;
    }
  }
  // re-try again
  return yield apiCall;
}

export function* apiCall<Fn extends (...args: any[]) => any>(fn: Fn, ...args: Parameters<Fn>) {
  const apiCall = call(fn, ...args);
  return yield call(callApi, apiCall);
}

export function* onAppStart(action: ReturnType<typeof actions.onAppStart>) {
  const { location } = action.payload;
  const isAuthPath = !!authSections.find(auth => location.pathname.includes(auth));
  const isRootPath = location.pathname === '/';
  const isVerifyEmailPath = location.pathname === VERIFY_EMAIL;
  const isParentLink = location.pathname.includes(PARENT_PAYMENT);
  const isSuccessPayment = location.pathname.includes(PAYMENT_SUCCESS);

  yield fork(gaAnalytics.initializeAnalytics);

  if (isMaintenance) {
    yield put(push(MAINTENANCE));
    yield put(actions.appLoaded());

    return;
  }

  if (isIE) {
    yield put(push(BROWSER_NOT_SUPPORTED));
    yield put(actions.appLoaded());
    return;
  }

  if (isParentLink) {
    yield put(push(location.pathname));
    yield put(actions.setParentLink());
  }

  if (isSuccessPayment) {
    yield put(push({ pathname: location.pathname, search: location.search }));
  }

  if (isParentLink || isSuccessPayment) {
    yield put(actions.appLoaded());
    return;
  }

  let fetchTimer = false;

  const emailVerificationCode = location.search && new URLSearchParams(location.search).get('email-verification-code');

  if (emailVerificationCode) {
    const response = yield call(authenticationApi.verifyEmail, { code: emailVerificationCode });
    if (response.ok) {
      alert(t.VERIFY_EMAIL_SUCCESS, NotificationMode.SUCCESS);
      yield call(setup, response.data);
      yield put(actions.appLoaded());
      return;
    } else {
      alert(t[getErrorMessage(response)]);
    }
  }

  const resetTrialCode = location.search && new URLSearchParams(location.search).get('reset-trial-code');

  if (resetTrialCode) {
    const response = yield call(authenticationApi.resetTrial, { code: resetTrialCode });
    if (response.ok) {
      alert(t.RESET_TRIAL_SUCCESS, NotificationMode.SUCCESS);
      yield call(setup, response.data);
      yield put(actions.appLoaded());
      return;
    } else {
      alert(t[getErrorMessage(response)]);
    }
  }

  const hasRefreshToken = storage.getRefreshToken();

  if (hasRefreshToken) {
    yield call(authOperations.refreshAccessToken);

    if ((yield select(getSubscriptionExpired)).subscriptionExpired) {
      yield put(getUserSettings.request());
      yield put(getUserSubscription.request());
      yield put(actions.appLoaded());
      return;
    }

    if ((yield select(getVerificationRequired)).verificationRequired) {
      yield put(actions.appLoaded());
      return;
    }

    yield put(semesterActions.getCurrent.request());

    const semesterAction = yield take(getType(semesterActions.getCurrent.success));
    const { firstName, email, organizationId } = semesterAction.payload.user;
    const userId = yield call(storage.getUserId);

    if (!isMobileApp) {
      // yield fork(helpCrunch.authenticate, userId, email, firstName);
      yield fork(addFullStory, userId, email);
    }
    yield fork(gaAnalytics.identifyUser, email, organizationId);
    yield fork(sentryConfig.setUserContext, { email });

    yield put(commitmentsData.request());
    yield put(getUserSettings.request());
    yield put(getBadgesInfo.request());
    yield put(getUserSubscription.request());
    fetchTimer = true;
  }

  yield put(actions.appLoaded());

  if (isMobileApp) {
    yield put(push(location));
    return;
  } else {
    const isAccessibilityOn = yield select(commonSelectors.isAccessibilityOn);
    if (isAccessibilityOn) {
      yield fork(accessibe.includeScript);
    }
  }

  if (isMobile) {
    yield put(push(isRootPath ? SIGN_UP : location));
    return;
  }

  if (hasRefreshToken) {
    const semesterId = yield select(getId);
    const semesterState = yield select(getSemesterStatus);

    const defaultPathname = [SemesterStatus.COURSE_CREATED, SemesterStatus.TASK_CREATED].includes(semesterState)
      ? goToHomeLink(semesterId)
      : `/${semesterId}/term`;

    yield put(startNotificationsListener());
    if (
      ![SemesterStatus.CREATED, SemesterStatus.SEMESTER_SETUP_DONE, SemesterStatus.AWAKE_TIMES_SETUP_DONE].includes(
        semesterState
      )
    ) {
      yield put(triggerIcsUpdates.request());
    }

    yield put(push(isAuthPath || isRootPath || isVerifyEmailPath ? defaultPathname : location));
  } else {
    yield put(push(isRootPath ? LOGIN : location));
  }

  if (fetchTimer) {
    // this one needs to go last. It will not finish until we stop the timer
    yield call(getCurrentTimer);
  }
}

export function* parallelCalls(...apiCalls: any): any {
  const responses: any[] = yield all([...apiCalls]);

  for (const response of responses) {
    if (!response.ok) {
      return { ok: false, response: getErrorMessage(response) };
    }
  }

  return {
    ok: true,
    response: {
      ...responses.map(response => response.data).reduce((acc, currVal) => Object.assign(acc, currVal), {})
    }
  };
}

function* linkOrUnlinkGoogleCalendar(action: ReturnType<typeof actions.linkOrUnlinkGoogleCalendar>) {
  const { id, googleCalendarId } = action.payload;
  // if calendar id exists, unlink
  if (googleCalendarId) {
    yield put(courseActions.updateCalendarId.request({ id, unlinkCalendarId: googleCalendarId }));
  } else {
    yield put(commonActions.toggleLinkToGoogleCalendarDialog({ id, googleCalendarId }));
  }
}

function* toggleAccessibility(action: ReturnType<typeof actions.toggleAccessibility>) {
  const wasOn = yield call(storage.isAccessibilityOn);
  // turn on/off script
  yield call(wasOn ? accessibe.removeScript : accessibe.includeScript);
  const isOn = !wasOn;
  yield put(actions.setAccessibility(isOn));
  yield call(storage.setAccessibilityOn, isOn);
}

function* goToSuccessPaymentPage(action: ReturnType<typeof actions.goToSuccessPaymentPage>) {
  const location = action.payload;
  yield put(push(location));
}

export default function*() {
  // @ts-ignore
  yield all([
    takeLatest(actions.onAppStart, onAppStart),
    takeLatest(actions.linkOrUnlinkGoogleCalendar, linkOrUnlinkGoogleCalendar),
    takeLatest(actions.goToSuccessPaymentPage, goToSuccessPaymentPage),
    takeLatest(actions.toggleAccessibility, toggleAccessibility)
  ]);
}
